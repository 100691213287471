import $ from "jquery";

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/modal';

import UIBindings from "./ui-bindings";
import { Forms } from "./form";

class PageTimer {
    getLoadTime = function () {
        var now = new Date().getTime();
        // Get the performance object
        window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
        var timing = performance.timing || {};
        var ms = now - timing.navigationStart;
        return Math.round(ms / 10) / 100;
    }

    logToConsole = function () {
        let loadTime = this.getLoadTime();
        $(window).on('load', function () {
            console && console.info && console.info(`Client loaded in ${loadTime}s`);
        });
    }

    append = function (holder) {
        let loadTime = this.getLoadTime();
        $(window).on('load', function () {
            holder.text(' | LT: ' + loadTime() + 's');
        });
    }
}

class StickyFooter {
    constructor($wrap, $footer) {
        this.$window = $(window);
        this.$wrap = $($wrap);
        this.$footer = $($footer);
    }

    updateWrapperCSS = function () {
        let footerHeight = this.$footer.outerHeight();
        this.$wrap.css({
            marginBottom: -1 * footerHeight,
            paddingBottom: footerHeight
        });
    }

    bindOnResize = function () {
        this.$window.on('resize', this.updateWrapperCSS.bind(this));
        return this;
    }

    bindOnLoad = function () {
        this.$window.on('load', this.updateWrapperCSS.bind(this));
        return this;
    }

    init = function () {
        this.updateWrapperCSS();
        this.bindOnLoad();
        this.bindOnResize();
        return this;
    }
}

class ExternalLinks {
    hostname = document.location.hostname;
    matchRegex = [new RegExp("^https?:\/\/(.*?)" + this.hostname)];
    context = null;

    constructor(selector) {
        this.context = $(selector);
    }

    addLinkTarget() {
        var self = this;
        
        this.context.find('a').filter('[href^="http://"], [href^="https://"]').each(function () {
            var anchor = $(this);
            var href = anchor.attr('href');
            var isInternalLink = false;

            for (var i = 0; i < self.matchRegex.length; i++) {
                var regex = self.matchRegex[i];
                if (href.match(regex)) {
                    isInternalLink = true;
                }
            }

            if (!isInternalLink) {
                anchor.attr('target', '_blank').addClass('external-link');
            }
        });
    }
}

(new PageTimer()).logToConsole();
(new StickyFooter($('#container'), $('#footer'))).init();
//(new ExternalLinks($('body'))).addLinkTarget();

const UIBinding = new UIBindings();

UIBinding.formSubmitButton();
UIBinding.magnificPopup();
UIBinding.slickCarourel();
UIBinding.share();

const forms = new Forms();
forms.validate();

$(function () {
    var hoursTotal = $('#hours-total');
    var host = window.location.host.replace('www', 'app');

    if (hoursTotal.length) {
        $.ajax({
            url: window.location.protocol + '//' + host + '/time-total',
            success: function (data) {
                console.log('data', data);
                hoursTotal.fadeOut(0);
                hoursTotal.removeClass('invisible');
                hoursTotal.fadeIn();
                hoursTotal.html(hoursTotal.html().replace('%total%', data.total_formatted));
            }
        });
    }

});