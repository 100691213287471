import $ from "jquery";
import "magnific-popup";
import "slick-carousel";
import { ShareHandler } from "./share";
export default class UIBindings {

    formSubmitButton() {
        $(document).on('submit', 'form', function () {
            let submitText = $(this).find('[data-submit-text]');
            let submittingText = $(this).find('[data-submitting-text]');
            let btn = submitText.closest('button');
            
            submittingText.removeClass('d-none');
            submitText.addClass('d-none');
            btn.prop('disabled', true);
        });
    }
    magnificPopup () {
        let image = $('[mfp-type="image"]');
        let gallery = $('[mfp-type="gallery"]');

        if (image.length > 0) {
            image.magnificPopup({type: "image"});
        }
        if (gallery.length > 0) {
            gallery.magnificPopup({
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: true,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    tCounter: '%curr% of %total%',
                    preload: [0, 2] // Will preload 0 - before current, and 1 after the current image
                },
                zoom: {
                    enabled: true,
                    duration: 500 // don't foget to change the duration also in CSS
                }
            });
        }
    }

    slickCarourel() {
        let carousel = $('[data-slick]');
        if (carousel.length > 0) {
            carousel.slick();
        }
    }

    share() {
        const shareHandler = new ShareHandler();
        shareHandler.build();
    }

}